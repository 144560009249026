// setup & base
@import 'shared/bourbon/bourbon'
@import 'shared/reset/reset'
@import 'theme/settings/grid'
@import 'shared/flex-grid/flex-grid'
@import "~pikaday/scss/pikaday.scss"

// global styles
@import 'shared/settings/colors'
@import 'shared/settings/fonts'
@import 'theme/globals/global'

// component styles
@import 'theme/_extends/typography'
@import 'theme/_extends/buttons'
@import 'theme/_extends/select-input'
@import 'theme/_extends/label'
@import 'theme/_extends/text-input'

@import 'theme/components/account-info'
@import 'theme/components/nav'
@import 'theme/components/table'
@import 'theme/components/toolbar'
@import 'theme/components/filters'
@import 'theme/components/pagination'
@import 'theme/components/header-and-content'
@import 'theme/components/form'
@import 'theme/components/panel'
@import 'theme/components/datepicker'
@import 'theme/components/flash-messages'

// page styles
@import 'theme/pages/index'
@import 'theme/pages/show'
@import 'theme/pages/edit'
@import 'theme/pages/new'
