%select-input
  transition: all 350ms ease-in-out
  appearance: none
  outline: 0
  margin: 0
  display: block
  border: 1px solid darken($gallery-gray, 10%)
  border-radius: 0px
  padding: 9px 25px 9px 10px
  font-family: $helvetica-neue
  font-size: 14px
  background: url('../images/down-arrow.png') no-repeat 97% 50% $white
  background-size: 17px
  background-position: 94%
  color: $dark-gray

  &:focus,
  &:hover
    border-color: $dark-gray
