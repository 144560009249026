%text-input
  transition: all 350ms ease-in-out
  appearance: none
  outline: none
  background: none
  margin: 0
  padding: 10px
  font-family: $helvetica-neue
  font-size: 14px
  color: $dark-gray
  border: 1px solid darken($gallery-gray, 10%)
  display: block
  background-color: $white

  &:focus,
  &:hover
    border-color: $dark-gray
    border-width: 1px
