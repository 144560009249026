section#torch-account-info
  background-color: $ir-red
  text-align: right

  a
    margin: 6px 0
    display: inline-block
    padding: 0 10px
    border-right: 1px solid $white
    color: $white
    font-family: $helvetica-neue
    font-size: 14px

  .torch-container
    a
      &:last-child
        border-right: 0px

