html
  background: $gallery-gray
  font-family: $helvetica-neue
  font-weight: normal
  min-height: 100vh

  body
    min-height: 100vh

  a
    text-decoration: none

  .torch-container
    margin:        0 auto
    padding-right: 15px
    padding-left:  15px
