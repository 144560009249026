section#torch-nav
  background-color: $alabaster-gray

  .torch-container
    display: flex

  .torch-logo
    padding: 6px 0px

    a
      display: flex

      h3
        color: $ir-red
        font-family: $helvetica-neue
        text-transform: uppercase
        font-size: 18px
        font-weight: bold
        margin: 15px 10px

      &:before
        content: " "
        display: block
        background-image: url("../images/torch-logo.png")
        width: 50px
        height: 50px
        background-size: contain
        background-repeat: no-repeat

  .torch-nav
    display: flex

    a
      display: block
      font-size: 16px
      padding: 22px 10px 19px 10px
      border-bottom: 3px solid transparent
      color: lighten($dark-gray, 25%)

      &:hover
        color: $dark-gray

      &.active
        border-color: $ir_red
        color: $dark-gray
