.torch-datepicker
  .is-today
    &:not(.is-selected)
      .pika-button
        color: $ir-red

  .pika-button
    &:hover
      background: $ir-red
      color: $white !important

  .is-selected
    .pika-button
      background: $ir-red
      box-shadow: none

      &:hover
        background: $ir-red