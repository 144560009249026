body.torch-show
  header
    margin-bottom: 20px

  section#torch-toolbar
    margin-bottom: 20px

  .torch-container header
    margin-bottom: 0

  .torch-show-details
    padding: 20px
    background-color: $white

  .torch-show-attribute
    display: flex
    padding: 0.5rem 0

    .torch-show-label
      border-right: 1px solid $dark-gray
      flex: 1
      margin-right: 1rem
      padding-right: 1rem
      text-align: right

    .torch-show-data
      flex: 4
