section#torch-filters
  background-color: $alabaster-gray
  padding: 20px

  h3
    @extend %h3-gray-text
    text-transform: capitalize

  hr
    appearance: none
    border: none
    border-bottom: 1px solid darken($gallery-gray, 10%)
    margin: 20px 0 10px

  .field
    display: flex
    flex-wrap: wrap

    label
      @extend %base-label
      flex: 1
      flex-basis: 100%

    select
      @extend %select-input
      flex: 1
      margin-right: 20px
      max-width: 30%

    input[type="number"]
      @extend %text-input
      flex: 3

    input[type="text"]
      @extend %text-input
      flex: 3

      &.datepicker
        width: calc(50% - 10px)

        &.start
          margin-right: 20px

  button
    @extend %default-button
    margin-top: 10px

  a
    margin-left: 20px
    color: $ir-red
