body.torch-index
  section#torch-nav
    margin-bottom: 20px

  section#torch-toolbar
    margin-bottom: 20px

  section#torch-index-content
    .torch-container
      display: flex

      section#torch-filters
        flex: 1
        margin-left: 35px
        order: 2

      section#torch-table
        flex: 3
        order: 1
        width: 70%

        table
          margin-bottom: 20px

          th
            text-align: left

          th:last-child
            text-align: right

          .torch-actions
            text-align: right

      section#torch-pagination
        display: flex
        justify-content: center
        margin-bottom: 20px
