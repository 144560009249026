section#torch-header-and-content
  .header
    background-color: $alabaster-gray
    padding: 15px 20px
    border-top: 4px solid $ir-red
    border-radius: 3px
    border-bottom: 1px solid $gallery-gray

    h3
      @extend %h3-gray-text

  ul
    padding: 20px
    background-color: $white


    li
      display: flex
      padding: 10px 0

      .torch-show-label
        width: 20%
        text-align: center

      .torch-show-data
        width: 80%


