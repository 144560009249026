section#torch-toolbar
  .torch-container
    display: flex
    justify-content: flex-end

  a
    @extend %default-button
    margin-right: 20px

    &:last-child
      margin-right: 0px