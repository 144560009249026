html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: none;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.pika-single {
  z-index: 9999;
  color: #333;
  background: #fff;
  border: 1px solid #ccc;
  border-bottom-color: #bbb;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  display: block;
  position: relative;
}

.pika-single.is-hidden {
  display: none;
}

.pika-single.is-bound {
  position: absolute;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, .5);
}

.pika-single:before, .pika-single:after {
  content: " ";
  display: table;
}

.pika-single:after {
  clear: both;
}

.pika-lendar {
  float: left;
  width: 240px;
  margin: 8px;
}

.pika-title {
  text-align: center;
  position: relative;
}

.pika-title select {
  cursor: pointer;
  z-index: 9998;
  opacity: 0;
  margin: 0;
  position: absolute;
  top: 5px;
  left: 0;
}

.pika-label {
  z-index: 9999;
  color: #333;
  background-color: #fff;
  margin: 0;
  padding: 5px 3px;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.pika-prev, .pika-next {
  cursor: pointer;
  width: 20px;
  height: 30px;
  text-indent: 20px;
  white-space: nowrap;
  opacity: .5;
  background-color: rgba(0, 0, 0, 0);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 75% 75%;
  border: none;
  outline: none;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.pika-prev:hover, .pika-next:hover {
  opacity: 1;
}

.pika-prev.is-disabled, .pika-next.is-disabled {
  cursor: default;
  opacity: .2;
}

.pika-prev, .is-rtl .pika-next {
  float: left;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAUklEQVR42u3VMQoAIBADQf8Pgj+OD9hG2CtONJB2ymQkKe0HbwAP0xucDiQWARITIDEBEnMgMQ8S8+AqBIl6kKgHiXqQqAeJepBo/z38J/U0uAHlaBkBl9I4GwAAAABJRU5ErkJggg==");
}

.pika-next, .is-rtl .pika-prev {
  float: right;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAU0lEQVR42u3VOwoAMAgE0dwfAnNjU26bYkBCFGwfiL9VVWoO+BJ4Gf3gtsEKKoFBNTCoCAYVwaAiGNQGMUHMkjGbgjk2mIONuXo0nC8XnCf1JXgArVIZAQh5TKYAAAAASUVORK5CYII=");
}

.pika-select {
  display: inline-block;
}

.pika-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: none;
}

.pika-table th, .pika-table td {
  width: 14.2857%;
  padding: 0;
}

.pika-table th {
  color: #999;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  line-height: 25px;
}

.pika-table abbr {
  cursor: help;
  border-bottom: none;
}

.pika-button {
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  color: #666;
  text-align: right;
  height: initial;
  background: #f5f5f5;
  border: none;
  outline: none;
  margin: 0;
  padding: 5px;
  font-size: 12px;
  line-height: 15px;
  display: block;
}

.has-event .pika-button {
  color: #fff;
  background: #3af;
}

.is-today .pika-button {
  color: #3af;
  font-weight: bold;
}

.is-selected .pika-button {
  color: #fff;
  background: #3af;
  border-radius: 3px;
  font-weight: bold;
  box-shadow: inset 0 1px 3px #178fe5;
}

.is-disabled .pika-button, .is-outside-current-month .pika-button {
  color: #999;
  opacity: .3;
}

.is-disabled .pika-button {
  pointer-events: none;
  cursor: default;
}

.pika-button:hover {
  color: #fff;
  box-shadow: none;
  background: #ff8000;
  border-radius: 3px;
}

.pika-button .is-selection-disabled {
  pointer-events: none;
  cursor: default;
}

.pika-week {
  color: #999;
  font-size: 11px;
}

.is-inrange .pika-button {
  color: #666;
  background: #d5e9f7;
}

.is-startrange .pika-button {
  color: #fff;
  box-shadow: none;
  background: #6cb31d;
  border-radius: 3px;
}

.is-endrange .pika-button {
  color: #fff;
  box-shadow: none;
  background: #3af;
  border-radius: 3px;
}

html {
  min-height: 100vh;
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: normal;
}

html body {
  min-height: 100vh;
}

html a {
  text-decoration: none;
}

html .torch-container {
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.torch-panel legend, section#torch-header-and-content .header h3, section#torch-filters h3 {
  color: #666;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
}

form#torch-form .torch-submit-form .torch-submit-button, section#torch-table table td button, section#torch-filters button, section#torch-toolbar a {
  cursor: pointer;
  color: #666;
  text-transform: capitalize;
  background-color: #eee;
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 9px 12px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  transition: all .25s ease-in-out;
}

section#torch-filters button, section#torch-toolbar a {
  border-color: rgba(0, 0, 0, .1);
}

section#torch-filters button:hover, section#torch-toolbar a:hover {
  background-color: #c8c8c8;
}

form#torch-form .torch-submit-form .torch-submit-button, section#torch-table table td button {
  color: #fff;
  background-color: #e73536;
}

form#torch-form .torch-submit-form .torch-submit-button:hover, section#torch-table table td button:hover {
  background-color: #b91617;
}

form#torch-form .torch-form-group select, section#torch-filters .field select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #666;
  background: #fff url("down-arrow.e17911d3.png") 94% / 17px no-repeat;
  border: 1px solid #d5d5d5;
  border-radius: 0;
  outline: none;
  margin: 0;
  padding: 9px 25px 9px 10px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  transition: all .35s ease-in-out;
  display: block;
}

form#torch-form .torch-form-group select:focus, section#torch-filters .field select:focus, form#torch-form .torch-form-group select:hover, section#torch-filters .field select:hover {
  border-color: #666;
}

section#torch-filters .field label {
  color: #666;
  padding: 10px 0;
  font-size: 14px;
  display: block;
}

form#torch-form .torch-form-group input[type="number"], form#torch-form .torch-form-group input[type="text"], form#torch-form .torch-form-group input[type="password"], form#torch-form .torch-form-group textarea, section#torch-filters .field input[type="text"], section#torch-filters .field input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #666;
  background: #fff;
  border: 1px solid #d5d5d5;
  outline: none;
  margin: 0;
  padding: 10px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  transition: all .35s ease-in-out;
  display: block;
}

form#torch-form .torch-form-group input[type="number"]:focus, form#torch-form .torch-form-group input[type="text"]:focus, form#torch-form .torch-form-group input[type="password"]:focus, form#torch-form .torch-form-group textarea:focus, section#torch-filters .field input[type="text"]:focus, section#torch-filters .field input[type="number"]:focus, form#torch-form .torch-form-group input[type="number"]:hover, form#torch-form .torch-form-group input[type="text"]:hover, form#torch-form .torch-form-group input[type="password"]:hover, form#torch-form .torch-form-group textarea:hover, section#torch-filters .field input[type="text"]:hover, section#torch-filters .field input[type="number"]:hover {
  border-width: 1px;
  border-color: #666;
}

section#torch-account-info {
  text-align: right;
  background-color: #e73536;
}

section#torch-account-info a {
  color: #fff;
  border-right: 1px solid #fff;
  margin: 6px 0;
  padding: 0 10px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  display: inline-block;
}

section#torch-account-info .torch-container a:last-child {
  border-right: none;
}

section#torch-nav {
  background-color: #fafafa;
}

section#torch-nav .torch-container {
  display: flex;
}

section#torch-nav .torch-logo {
  padding: 6px 0;
}

section#torch-nav .torch-logo a {
  display: flex;
}

section#torch-nav .torch-logo a h3 {
  color: #e73536;
  text-transform: uppercase;
  margin: 15px 10px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: bold;
}

section#torch-nav .torch-logo a:before {
  content: " ";
  width: 50px;
  height: 50px;
  background-image: url("torch-logo.a963df87.png");
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
}

section#torch-nav .torch-nav {
  display: flex;
}

section#torch-nav .torch-nav a {
  color: #a6a6a6;
  border-bottom: 3px solid rgba(0, 0, 0, 0);
  padding: 22px 10px 19px;
  font-size: 16px;
  display: block;
}

section#torch-nav .torch-nav a:hover {
  color: #666;
}

section#torch-nav .torch-nav a.active {
  color: #666;
  border-color: #e73536;
}

section#torch-table {
  overflow-x: auto;
}

section#torch-table .torch-no-data {
  text-transform: capitalize;
  color: #666;
}

section#torch-table table {
  color: #666;
  width: 100%;
  background-color: #fff;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
}

section#torch-table table th {
  color: #666;
  vertical-align: middle;
  background-color: #fafafa;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 0;
}

section#torch-table table th:hover {
  background-color: #d4d4d4;
}

section#torch-table table th:first-child {
  border-left: 1px solid #eee;
}

section#torch-table table th:last-child {
  border-right: 1px solid #eee;
}

section#torch-table table th .caret {
  height: 0;
  width: 0;
  border-left: 5px solid rgba(0, 0, 0, 0);
  border-right: 5px solid rgba(0, 0, 0, 0);
  margin-top: 5px;
  margin-left: 5px;
  display: inline-block;
  position: absolute;
  top: 12px;
  right: 10px;
}

section#torch-table table th a, section#torch-table table th span:not(.caret) {
  color: #666;
  padding: 12px 10px;
  text-decoration: none;
  display: block;
  position: relative;
}

section#torch-table table th a.active.desc .caret {
  border-top: 5px solid #666;
}

section#torch-table table th a.active.asc .caret {
  border-bottom: 5px solid #666;
}

section#torch-table table td {
  text-overflow: ellipsis;
  vertical-align: middle;
  max-width: 300px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 15px 10px;
  overflow: hidden;
}

section#torch-table table td:first-child {
  border-left: 1px solid #eee;
}

section#torch-table table td:last-child {
  border-right: 1px solid #eee;
}

section#torch-table table td a {
  color: #e73536;
  text-decoration: none;
}

section#torch-table table td img {
  max-height: 30px;
  max-width: 30px;
}

section#torch-toolbar .torch-container {
  justify-content: flex-end;
  display: flex;
}

section#torch-toolbar a {
  margin-right: 20px;
}

section#torch-toolbar a:last-child {
  margin-right: 0;
}

section#torch-filters {
  background-color: #fafafa;
  padding: 20px;
}

section#torch-filters h3 {
  text-transform: capitalize;
}

section#torch-filters hr {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-bottom: 1px solid #d5d5d5;
  margin: 20px 0 10px;
}

section#torch-filters .field {
  flex-wrap: wrap;
  display: flex;
}

section#torch-filters .field label {
  flex: 100%;
}

section#torch-filters .field select {
  max-width: 30%;
  flex: 1;
  margin-right: 20px;
}

section#torch-filters .field input[type="number"] {
  flex: 3;
}

section#torch-filters .field input[type="text"] {
  flex: 3;
}

section#torch-filters .field input[type="text"].datepicker {
  width: calc(50% - 10px);
}

section#torch-filters .field input[type="text"].datepicker.start {
  margin-right: 20px;
}

section#torch-filters button {
  margin-top: 10px;
}

section#torch-filters a {
  color: #e73536;
  margin-left: 20px;
}

section#torch-pagination ul {
  display: flex;
}

section#torch-pagination li a {
  color: #e73536;
  background-color: #fff;
  border: 1px solid #eee;
  margin-left: -1px;
  padding: 10px 15px;
  text-decoration: none;
  display: block;
}

section#torch-pagination li a:hover {
  background-color: #fafafa;
}

section#torch-pagination li a.active {
  color: #fff;
  background-color: #e73536;
}

section#torch-pagination li:first-child a {
  margin-left: 0;
}

section#torch-header-and-content .header {
  background-color: #fafafa;
  border-top: 4px solid #e73536;
  border-bottom: 1px solid #eee;
  border-radius: 3px;
  padding: 15px 20px;
}

section#torch-header-and-content ul {
  background-color: #fff;
  padding: 20px;
}

section#torch-header-and-content ul li {
  padding: 10px 0;
  display: flex;
}

section#torch-header-and-content ul li .torch-show-label {
  width: 20%;
  text-align: center;
}

section#torch-header-and-content ul li .torch-show-data {
  width: 80%;
}

form#torch-form {
  background-color: #fff;
  padding: 20px;
  display: flex;
}

form#torch-form .torch-form-error {
  color: #fff;
  text-align: center;
  background-color: #f6bb42;
  margin-bottom: 20px;
  padding: 5px;
}

form#torch-form .torch-form-group {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

form#torch-form .torch-form-group label {
  color: #666;
  text-align: right;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 15px;
  display: flex;
}

form#torch-form .torch-form-group input[type="text"], form#torch-form .torch-form-group input[type="password"], form#torch-form .torch-form-group textarea {
  flex: 1;
}

form#torch-form .torch-form-group .help-block {
  width: 70%;
  text-transform: capitalize;
  color: #f6bb42;
  margin-left: calc(30% + 12px);
  font-size: 14px;
}

form#torch-form .torch-form-group-input {
  flex-wrap: wrap;
  flex: 3;
  align-items: center;
  display: flex;
}

form#torch-form .torch-form-group-input select:first-child {
  margin-left: 0;
}

form#torch-form .torch-form-group-input select {
  margin-left: .5em;
  margin-right: .5em;
}

form#torch-form .torch-form-group-input .invalid-feedback {
  flex: 100%;
}

form#torch-form .torch-submit-form {
  justify-content: flex-end;
  display: flex;
}

.torch-panel {
  border: 2px solid #eee;
  flex: 1;
  padding: 25px;
}

.torch-panel legend {
  padding: 0 6px;
}

.torch-datepicker .is-today:not(.is-selected) .pika-button {
  color: #e73536;
}

.torch-datepicker .pika-button:hover {
  background: #e73536;
  color: #fff !important;
}

.torch-datepicker .is-selected .pika-button {
  box-shadow: none;
  background: #e73536;
}

.torch-datepicker .is-selected .pika-button:hover {
  background: #e73536;
}

section#torch-flash-messages {
  margin-bottom: 20px;
}

section#torch-flash-messages .torch-flash {
  text-align: center;
  color: #fff;
  padding: 8px;
}

section#torch-flash-messages .torch-flash.error {
  background-color: #f6bb42;
}

section#torch-flash-messages .torch-flash.info {
  background-color: #4a89dc;
}

section#torch-flash-messages .torch-flash.success {
  background-color: #70ca63;
}

section#torch-flash-messages .torch-flash .torch-flash-close {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: inherit;
  color: #fff;
  width: 22px;
  height: 22px;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 50%;
}

section#torch-flash-messages .torch-flash .torch-flash-close:focus {
  outline: none;
}

body.torch-index section#torch-nav, body.torch-index section#torch-toolbar {
  margin-bottom: 20px;
}

body.torch-index section#torch-index-content .torch-container {
  display: flex;
}

body.torch-index section#torch-index-content .torch-container section#torch-filters {
  flex: 1;
  order: 2;
  margin-left: 35px;
}

body.torch-index section#torch-index-content .torch-container section#torch-table {
  width: 70%;
  flex: 3;
  order: 1;
}

body.torch-index section#torch-index-content .torch-container section#torch-table table {
  margin-bottom: 20px;
}

body.torch-index section#torch-index-content .torch-container section#torch-table table th {
  text-align: left;
}

body.torch-index section#torch-index-content .torch-container section#torch-table table th:last-child, body.torch-index section#torch-index-content .torch-container section#torch-table table .torch-actions {
  text-align: right;
}

body.torch-index section#torch-index-content .torch-container section#torch-pagination {
  justify-content: center;
  margin-bottom: 20px;
  display: flex;
}

body.torch-show header, body.torch-show section#torch-toolbar {
  margin-bottom: 20px;
}

body.torch-show .torch-container header {
  margin-bottom: 0;
}

body.torch-show .torch-show-details {
  background-color: #fff;
  padding: 20px;
}

body.torch-show .torch-show-attribute {
  padding: .5rem 0;
  display: flex;
}

body.torch-show .torch-show-attribute .torch-show-label {
  text-align: right;
  border-right: 1px solid #666;
  flex: 1;
  margin-right: 1rem;
  padding-right: 1rem;
}

body.torch-show .torch-show-attribute .torch-show-data {
  flex: 4;
}

body.torch-edit header, body.torch-update header, body.torch-edit section#torch-toolbar, body.torch-update section#torch-toolbar, body.torch-new header, body.torch-create header, body.torch-new section#torch-toolbar, body.torch-create section#torch-toolbar {
  margin-bottom: 20px;
}

/*# sourceMappingURL=theme.css.map */
