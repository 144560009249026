form#torch-form
  background-color: $white
  display: flex
  padding: 20px

  .torch-form-error
    background-color: $orange
    color: $white
    margin-bottom: 20px
    padding: 5px
    text-align: center

  .torch-form-group
    align-items: center
    display: flex
    flex-wrap: wrap
    margin-bottom: 20px

    label
      display: flex
      align-items: center
      justify-content: flex-end
      font-size: 15px
      font-family: $helvetica-neue
      color: $dark-gray
      flex: 1
      text-align: right
      margin-right: 20px

    input[type="text"],
    input[type="password"],
    textarea
      @extend %text-input
      flex: 1

    input[type="number"]
      @extend %text-input

    select
      @extend %select-input

    .help-block
      margin-left: calc(30% + 12px)
      width: 70%
      text-transform: capitalize
      color: $orange
      font-size: 14px

  .torch-form-group-input
    align-items: center
    display: flex
    flex: 3
    flex-wrap: wrap

    select:first-child
      margin-left: 0

    select
      margin-right: 0.5em
      margin-left: 0.5em


    .invalid-feedback
      flex: 1
      flex-basis: 100%

  .torch-submit-form
    display: flex
    justify-content: flex-end

    .torch-submit-button
      @extend %red-button
